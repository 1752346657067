<template>
    <div class="home" >
        <!-- 头部 -->
        <!-- <largeHead :tabArrShow="tabArrShow"></largeHead> -->

        <!-- 主体内容 -->
        <div class="mallContent" v-loading="loading">
            <div class="son" v-for="(item, index) in shoppingList" :key="index">
                <div class="head">
                    <div class="img">
                        <img :src="item.storeLog" alt="">
                    </div>
                    <div class="info">
                        <div class="name">{{ item.companyName }} <span>诚{{ item.storeGrade }}</span> </div>
                        <div class="text">主营：{{ item.storeMajor }}</div>
                    </div>
                    <div class="enterThe cur" @click="dpClick(item.storeId)">进入店铺</div>
                </div>
                <div class="list">
                    <div class="img" v-for="(i, index) in item.mallSpus" :key="index">
                        <img class="cur" :src="i.mainImage" alt="" @click="spuClick(i.spuId)">
                    </div>
                </div>
            </div>
        </div>

        <!-- 分页 -->
        <!-- <div class="paging">
            <el-pagination background layout="prev, pager, next"  @current-change="handleCurrentChange" :total="shoppingListTotal">
            </el-pagination>
        </div> -->
        <paging :total="shoppingListTotal" @handleCurrentChange="handleCurrentChange" :page-size="10"></paging>

        <!-- 底部 -->
        <!-- <bottomContent></bottomContent> -->
    </div>
</template>

<script>
import largeHead from "@/components/largeHead.vue";
import bottomContent from "@/components/bottomContent.vue";
import paging from "@/components/paging.vue";
export default {
    name: 'boutiqueMall',
    components: {
        largeHead,
        bottomContent,
        paging
    },
    data() {
        return {
            loading:true,
            // 头部激活菜单
            tabArrShow: 1,
            // 精品商城列表
            shoppingList: [],
            shoppingListTotal: 0
        };
    },
    mounted() {
        this.shoppingList = this.$store.state.shoppingList
        this.shoppingListTotal = this.$store.state.shoppingListTotal
        this.loading = false
    },
    watch: {
        '$store.state.shoppingList'(newVal, oldVal) {
            this.loading = false
            this.shoppingList = this.$store.state.shoppingList
            this.shoppingListTotal = this.$store.state.shoppingListTotal
            console.log(this.shoppingList,'精品商城')
        },
    },
    methods:{
        handleCurrentChange(val){
            this.loading = true
            console.log(val)
            this.$store.commit('getShoppingList',{size:6,current:val})
        },
        spuClick(ids){
            console.log(ids)
            window.open(`#/commodity/productDetails?ids=${ids}`, '_blank');
        },
        dpClick(ids){
            window.open(`#/enterpriseHome/enterpriseContent?ids=${ids}`, '_blank');
        }
    }
}
</script>

<style lang="less" scoped>
.home {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;

    >.mallContent {
        width: 1200px;
        height: auto;
        margin: 30px auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        >.son {
            width: 585px;
            height: 242px;
            border-radius: 4px;
            background-color: #fff;
            padding: 15px;
            box-sizing: border-box;
            margin-bottom: 20px;

            >.head {
                width: 100%;
                height: 70px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                >.img {
                    width: 67px;
                    height: 67px;
                    border-radius: 50%;
                    overflow: hidden;
                    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);

                    >img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                    }
                }

                >.info {
                    width: 370px;
                    height: 50px;

                    >.name {
                        font-size: 20px;
                        font-weight: bold;
                        color: #333;

                        >span {
                            display: inline-block;
                            width: 28px;
                            height: 16px;
                            background-color: #FF4242;
                            color: #fff;
                            margin-left: 5px;
                            text-align: center;
                            line-height: 16px;
                            font-size: 12px;
                            border-radius: 25px;
                            font-weight: 300;
                        }
                    }

                    >.text {
                        font-size: 12px;
                        color: #666;
                        width: 100%;
                        white-space: nowrap;
                        margin-top: 8px;
                        /* 禁止换行 */
                        overflow: hidden;
                        /* 超出部分隐藏 */
                        text-overflow: ellipsis;
                        /* 超出部分显示省略号 */
                    }
                }

                >.enterThe {
                    width: 78px;
                    height: 28px;
                    border-radius: 20px;
                    background-color: #FF4242;
                    font-size: 12px;
                    color: #fff;
                    text-align: center;
                    line-height: 28px;
                    margin-top: -20px;
                }
            }

            >.list {
                width: 100%;
                height: auto;
                display: flex;
                // justify-content: space-between;
                align-items: center;
                margin-top: 20px;

                >.img {
                    width: 120px;
                    height: 120px;
                    border-radius: 6px;
                    overflow: hidden;
                    border: 1px solid #fff;
                    transition: all .3s;
                    margin-right: 18px;

                    >img {
                        width: 100%;
                        height: auto;
                    }
                }

                >.img:hover {
                    border: 1px solid #FF4242;
                    transition: all .3s;
                }
            }
        }
    }

    // 分页
    .paging {
        width: 1200px;
        height: 50px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 50px;
    }
}


// 分页样式
/deep/ .el-pagination .active {
    background-color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-pagination:hover .active:hover {
    color: #fff !important;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff;
    border: 1px solid #fff;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #ff4242;
    border: 1px solid #ff4242;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-right:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-left:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}
</style>